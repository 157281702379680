import M1_1 from "./1/1.jpg";
import M1_2 from "./1/2.jpg";

import M2_1 from "./2/1.jpg";
import M2_2 from "./2/2.jpg";

import M3_1 from "./3/1.jpg";
import M3_2 from "./3/2.jpg";

import M4_1 from "./4/1.jpg";
import M4_2 from "./4/2.jpg";

import M5_1 from "./5/1.jpg";
import M5_2 from "./5/2.jpg";

import M6_1 from "./6/1.jpg";
import M6_2 from "./6/2.jpg";

import M7_1 from "./7/1.jpg";
import M7_2 from "./7/2.jpg";

import M8_1 from "./8/1.jpg";
import M8_2 from "./8/2.jpg";

import M9_1 from "./9/1.jpg";
import M9_2 from "./9/2.jpg";

import M10_1 from "./10/1.jpg";
import M10_2 from "./10/2.jpg";

const imageDetails = [
  {
    src: { M1_1, M1_2 },
    key: "m1",
    alt: {
      tag: "Magnus 1",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 29 x L 12 x P 7 cm",
    },
  },
  {
    src: { M2_1, M2_2 },
    key: "m2",
    alt: {
      tag: "Magnus 2",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 27 x L 24 x P 7 cm",
    },
  },
  {
    src: { M3_1, M3_2 },
    key: "m3",
    alt: {
      tag: "Magnus 3",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 23 x L 16 x P 7 cm",
    },
  },
  {
    src: { M4_1, M4_2 },
    key: "m4",
    alt: {
      tag: "Magnus 4",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 40 x L 24 x P 14 cm",
    },
  },
  {
    src: { M5_1, M5_2 },
    key: "m5",
    alt: {
      tag: "Magnus 5",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 28 x L 17 x P 7 cm",
    },
  },
  {
    src: { M6_1, M6_2 },
    key: "m6",
    alt: {
      tag: "Magnus 6",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 20 x L 21 x P 7 cm",
    },
  },
  {
    src: { M7_1, M7_2 },
    key: "m7",
    alt: {
      tag: "Magnus 7",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 30 x L 25 x P 7 cm",
    },
  },
  {
    src: { M8_1, M8_2 },
    key: "m8",
    alt: {
      tag: "Magnus 8",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 18 x L 23 x P 7 cm",
    },
  },
  {
    src: { M9_1, M9_2 },
    key: "m9",
    alt: {
      tag: "Magnus 9",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 25 x L 24 x P 7 cm",
    },
  },
  {
    src: { M10_1, M10_2 },
    key: "m10",
    alt: {
      tag: "Magnus 10",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 22 x L 22 x P 7 cm",
    },
  },
];

export default imageDetails;
