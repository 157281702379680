import P1_1 from "./1/1.jpg";
import P1_2 from "./1/2.jpg";

import P2_1 from "./2/1.jpg";

import P3_1 from "./3/1.jpg";
import P3_2 from "./3/2.jpg";

import P4_1 from "./4/1.jpg";
import P4_2 from "./4/2.jpg";

import P5_1 from "./5/1.jpg";
import P5_2 from "./5/2.jpg";

import P6_1 from "./6/1.jpg";
import P6_2 from "./6/2.jpg";

import P7_1 from "./7/1.jpg";
import P7_2 from "./7/2.jpg";

import P8_1 from "./8/1.jpg";
import P8_2 from "./8/2.jpg";

import P9_1 from "./9/1.jpg";
import P9_2 from "./9/2.jpg";

import P10_1 from "./10/1.jpg";

import P11_1 from "./11/1.jpg";
import P11_2 from "./11/2.jpg";

import P12_1 from "./12/1.jpg";
import P12_2 from "./12/2.jpg";

import P13_1 from "./13/1.jpg";
import P13_2 from "./13/2.jpg";

import P14_1 from "./14/1.jpg";
import P14_2 from "./14/2.jpg";

import P15_1 from "./15/1.jpg";
import P15_2 from "./15/2.jpg";

const imageDetails = [
  {
    src: { P1_1, P1_2 },
    key: "p1",
    alt: {
      tag: "Primus 1",
      desc: "Acier doux - Brossé - Vernis",
      info: "H 54 x L 36 x P 20 cm",
    },
  },
  {
    src: { P2_1 },
    key: "p2",
    alt: {
      tag: "Primus 2",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 58 x L 35 x P 14 cm",
    },
  },
  {
    src: { P3_1, P3_2 },
    key: "p3",
    alt: {
      tag: "Primus 3",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 33 x L 25 x P 14 cm",
    },
  },
  {
    src: { P4_1, P4_2 },
    key: "p4",
    alt: {
      tag: "Primus 4",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 46 x L 40 x P 14 cm",
    },
  },
  {
    src: { P5_1, P5_2 },
    key: "p5",
    alt: {
      tag: "Primus 5",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 57 x L 46 x P 15 cm",
    },
  },
  {
    src: { P6_1, P6_2 },
    key: "p6",
    alt: {
      tag: "Primus 6",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 53 x L 36 x P 14 cm",
    },
  },
  {
    src: { P7_1, P7_2 },
    key: "p7",
    alt: {
      tag: "Primus 7",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 59 x L 40 x P 14 cm",
    },
  },
  {
    src: { P8_1, P8_2 },
    key: "p8",
    alt: {
      tag: "Primus 8",
      desc: "Acier doux - Brossé - Vernis",
      info: "H 54 x L 40 x P 14 cm",
    },
  },
  {
    src: { P9_1, P9_2 },
    key: "p9",
    alt: {
      tag: "Primus 9",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 43 x L 43 x P 7 cm",
    },
  },
  {
    src: { P10_1 },
    key: "p10",
    alt: {
      tag: "Primus 10",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 23 x L 29 x P 14 cm",
    },
  },
  {
    src: { P11_1, P11_2 },
    key: "p11",
    alt: {
      tag: "Primus 11",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 18 x L 17 x P 7 cm",
    },
  },
  {
    src: { P12_1, P12_2 },
    key: "p12",
    alt: {
      tag: "Primus 12",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 66 x L 40 x P 16 cm",
    },
  },
  {
    src: { P13_1, P13_2 },
    key: "p13",
    alt: {
      tag: "Primus 13",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 48 x L 33 x P 14 cm",
    },
  },
  {
    src: { P14_1, P14_2 },
    key: "p14",
    alt: {
      tag: "Primus 14",
      desc: "Acier doux - Brossé - Vernis",
      info: "H 56 x L 42 x P 14 cm",
    },
  },
  {
    src: { P15_1, P15_2 },
    key: "p15",
    alt: {
      tag: "Primus 15",
      desc: "Acier doux - Chauffé - Vernis",
      info: "H 52 x L 43 x P 14 cm",
    },
  },
];

export default imageDetails;
