import React from "react";
import { useNavigate } from "react-router-dom";

import imageDetailsMagnus from "../../assets/collection/magnus/import";
import imageDetailsPrimus from "../../assets/collection/primus/import";

export const Collection = ({ imageDetails }) => {
  const navigate = useNavigate();

  const handleImageClick = (key) => {
    navigate(`/detail/${key}`);
  };

  return (
    <div className=" mx-5 my-4 md:my-8 md:mx-auto grid grid-cols-2 gap-x-7 gap-y-7 md:gap-x-14 md:gap-y-10 max-w-3xl md:p-4 lg:p-0 lg:pt-4">
      {imageDetails.map((detail, index) => (
        <img
          key={index}
          alt={detail.alt}
          src={Object.values(detail.src)[0]}
          onClick={() => handleImageClick(detail.key)}
          className="cursor-pointer hover:opacity-90"
        />
      ))}
    </div>
  );
};

export const MagnusCollection = () => {
  return <Collection imageDetails={imageDetailsMagnus} />;
};

export const PrimusCollection = () => {
  return <Collection imageDetails={imageDetailsPrimus} />;
};
