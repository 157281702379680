import React from "react";

export const Contact = () => {
  return (
    <div className="text-center mt-4 md:mt-10 p-4 grid grid-rows-2 gap-5 text-xl	 max-w-sm  md:max-w-xl lg:max-w-2xl mx-auto leading-relaxed  text-Whitefef">
      <div className="p-3 bg-gray-800 ">
        <p>
          Atelier : <br />
          Domaine du deffend <br />
          Route d'alleins <br />
          13980 Alleins <br />
          <br />
          Vous avez une question, <br />
          n'hésitez pas à me joindre : <br />
          contact@fefmetal.fr <br />
          06 20 50 28 52
        </p>
      </div>
    </div>
  );
};
