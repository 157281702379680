import { NavLink } from "react-router-dom";

// square format
import imgMagnusHomeSquare from "../../assets/magnus_home.jpg";
import imgPrimusHomeSquare from "../../assets/primus_home.jpg";

function Square({ imgSrc, text, route }) {
  return (
    <div className="flex flex-col items-center justify-between bg-gray-600">
      <NavLink exact to={route}>
        <img src={imgSrc} alt={text} />
        <p className=" py-1 text-center justify-center md:text-3xl font-inter text-Whitefef">
          {text}
        </p>
      </NavLink>
    </div>
  );
}

const Home = () => {
  return (
    <div className="container mx-auto justify-center grid max-w-7xl md:grid-cols-2 md:gap-14 gap-7 p-12 ">
      <Square
        imgSrc={imgMagnusHomeSquare}
        text="Collection Magnus"
        route="/magnus"
      />
      <Square
        imgSrc={imgPrimusHomeSquare}
        text="Collection Primus"
        route="/primus"
      />
    </div>
  );
};

export { Home };
