import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  MagnusCollection,
  PrimusCollection,
} from "./screens/Collection/collection";

import { NavBar } from "./components/NavBar";
import { Home } from "./screens/Home/Home";
import { Contact } from "./screens/Contact/contact";
import { Artiste } from "./screens/Artiste/artiste";
import { FullScreen } from "./screens/FullScreen/fullScreen";

import "./App.css";

function App() {
  const [isNavbarVisible, setNavbarVisible] = useState(true);
  return (
    <div className="min-h-screen bg-Whitefef">
      <Router>
        {isNavbarVisible && <NavBar />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/magnus" element={<MagnusCollection />} />
          <Route path="/primus" element={<PrimusCollection />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/artiste" element={<Artiste />} />
          <Route
            path="/detail/:key"
            element={<FullScreen setNavbarVisible={setNavbarVisible} />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
