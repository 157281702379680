import React from "react";

export const Artiste = () => {
  return (
    <div class="container 2xl:w-3/5  mx-auto py-2 px-10 lg:py-8 lg:px-10">
      <p className=" mb-4 mt-6 text-md font-inter leading-relaxed md:text-lg ">
        Frédéric Sapey-Triomphe alias fef est un sculpteur sur métal français né
        à Lyon en 1974. Après des études en conception mécanique s'en suit
        l'immersion dans le monde de l'industrie. En 2010 il découvre le travail
        et l'amour du fer auprès d'un maître ferronier.
        <br />
        <br />
        S'ensuivra une décennie de collaboration pour la réalisation d'ouvrages
        tels qu'escaliers, portails ou gardes corps. Depuis il se consacre
        exclusivement à la sculpture. Du dessin industriel de ses débuts reste
        la précision et la finesse du trait, de l'artisanat d'art la rigueur et
        le respect de la matière.
        <br />
        <br />
        Observer, contempler notre planète bleue et bien plus loin l'inspire. Le
        plaisir commence avec la page blanche et le crayon de papier. Capter une
        forme, une sensation pour n'en garder que le mouvement, explorer et
        associer les courbes, jouer avec le vide pour harmoniser les formes
        pleines, pour au final une composition, une proposition.
        <br />
        <br />
        À la base l'acier, dur, froid, rude transformé pour donner vie à des
        formes courbes, légères, organiques. Tantôt d'aspect brossé, tantôt
        coloré, le feu pour figer la couleur dans la masse.
        <br />
        <br />
        Son quotidien : La meuleuse comme compagnon de route. Le chalumeau comme
        pinceau. Les étincelles en guise de feu d'artifice.
        <br />
        Son plaisir : Regarder l'expression du visage, écouter les mots et
        sensations de celui qui regarde et partager ce moment suspendu.
      </p>
    </div>
  );
};
