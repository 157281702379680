import { NavLink } from "react-router-dom";

export const NavBar = () => {
  return (
    <>
      <div className="flex flex-col items-center space-y-5">
        <NavLink exact to="/" className="mt-10 w-52 md:w-64 lg:w-80">
          <img
            alt="Fef logo"
            src="https://c.animaapp.com/u9tAVBJq/img/feflogo@2x.png"
          />
        </NavLink>

        <ul className="grid grid-cols-3 gap-x-4 lg:gap-x-32 text-md font-Inter text-gray-800 md:gap-x-20 md:text-2xl lg:text-3xl">
          <li className="flex justify-center">
            <NavLink exact to="/">
              SCULPTURES
            </NavLink>
          </li>
          <li className="flex justify-center">
            <NavLink exact to="/artiste">
              L’ARTISTE
            </NavLink>
          </li>
          <li className="flex justify-center">
            <NavLink exact to="/contact">
              CONTACT
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};
