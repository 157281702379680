import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { BiGridAlt } from "react-icons/bi";
import imageDetailsMagnus from "../../assets/collection/magnus/import";
import imageDetailsPrimus from "../../assets/collection/primus/import";

import { ButtonCarousel } from "../../components/ButtonCarousel";

export const FullScreen = ({ setNavbarVisible }) => {
  const { key } = useParams();
  const [images, setImages] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImgDisplay, setCurrentImgDisplay] = useState(1);
  const [IsMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isDetails = location.pathname.startsWith("/detail/");

  useEffect(() => {
    let collection;
    if (key.startsWith("m")) {
      collection = imageDetailsMagnus;
    } else {
      collection = imageDetailsPrimus;
    }

    const initialIndex = collection.findIndex((item) => item.key === key);
    if (initialIndex !== -1) {
      setCurrentIndex(initialIndex);
    }
    setImages(collection);
  }, [key]);

  useEffect(() => {
    setNavbarVisible(false);

    return () => {
      setNavbarVisible(true);
    };
  }, [setNavbarVisible]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleThumbnailClick = (index) => {
    setCurrentImgDisplay(index);
  };

  const goToPrev = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    setCurrentImgDisplay(1);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    setCurrentImgDisplay(1);
  };

  return (
    <div className="xl:flex justify-between">
      <div className="flex-1"></div>

      <div className="flex-1">
        <div className="carousel-container mx-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-md xl:max-w-xl pt-20 sm:pt-12 md:pt-10 lg:pt-1">
          <div className="mt-1" />
          {/* Back Button*/}
          <div className="flex justify-start">
            {isDetails && (
              <BiGridAlt
                className="text-3xl lg:text-4xl my-1 cursor-pointer"
                onClick={() => navigate(-1)}
              />
            )}
          </div>

          {/* Image Zone */}
          <div className="relative flex justify-center items-center ">
            <ButtonCarousel IsMobile={IsMobile} func={goToPrev} dir={true} />
            <div className="flex justify-center items-center overflow-hidden">
              <img
                src={
                  images[currentIndex]
                    ? Object.values(images[currentIndex].src)[
                        currentImgDisplay
                      ] || Object.values(images[currentIndex].src)[0]
                    : ""
                }
                alt={images[currentIndex] ? images[currentIndex].alt.desc : ""}
                className="object-contain"
                style={{
                  touchAction: "pinch-zoom",
                  userSelect: "none",
                  pointerEvents: "auto",
                }}
              />
            </div>
            <ButtonCarousel IsMobile={IsMobile} func={goToNext} dir={false} />
          </div>

          {/* Photos Thumbnail */}
          <div className="flex justify-center mt-4">
            {[0, 1].map((idx) => {
              const currentImage = images[currentIndex];
              if (currentImage && currentImage.src && currentImage.alt) {
                const srcValue = Object.values(currentImage.src)[idx];
                const altDesc = currentImage.alt.desc;

                return (
                  srcValue && (
                    <img
                      key={idx}
                      src={srcValue}
                      alt={altDesc}
                      className={`w-16 h-w-16 object-cover mx-2 cursor-pointer ${
                        idx === currentImgDisplay ? "border-2 border-black" : ""
                      }`}
                      onClick={() => handleThumbnailClick(idx)}
                    />
                  )
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>

      <div className="flex-1 flex justify-center items-center text-center">
        <div className="xl:mt-0 mt-6 pb-2 xl:pt-0">
          <div className="justify-start">
            <p className="text-lg ">{images[currentIndex]?.alt.tag}</p>
            <p className="text-md ">{images[currentIndex]?.alt.info}</p>
            <p className="text-lg ">{images[currentIndex]?.alt.desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
