import classNames from "classnames";

export const ButtonCarousel = ({ IsMobile, func, dir }) => {
  return (
    <button
      onClick={func}
      className={classNames("absolute text-2xl md:text-4xl p-2 rounded-full", {
        "left-0": dir,
        "right-0": !dir,
        "ml-[-50px]": !IsMobile && dir,
        "mr-[-50px]": !IsMobile && !dir,
        "text-Whitefef": IsMobile,
        "text-black": !IsMobile,
      })}
      style={IsMobile ? { padding: "10px" } : {}}
    >
      {dir ? "<" : ">"}
    </button>
  );
};
